import service from '@/utils/request'; // @Tags EquityGoods
// @Summary 获取退款单列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "获取退款单列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /refund/list [get]

export var repRefundList = function repRefundList(params) {
  return service({
    url: "/refund/list",
    method: 'get',
    params: params
  });
}; // @Tags EquityGoods
// @Summary 获取退款单详情
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "获取退款单详情"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router  /admin/refund/super/detail  [get]

export var repRefundDetail = function repRefundDetail(params) {
  return service({
    url: "/refund/detail",
    method: 'get',
    params: params
  });
}; // @Tags EquityGoods
// @Summary 获取退款单驳回
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "获取退款单驳回"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router  /refund/detail [post]

export var repRefundReject = function repRefundReject(data) {
  return service({
    url: "/refund/reject",
    method: 'post',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 获取退款单驳回
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "获取退款单驳回"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router  /refund/confirm [post]

export var repRefundConfirm = function repRefundConfirm(data) {
  return service({
    url: "/refund/confirm",
    method: 'post',
    data: data
  });
};