//
//
//
//
//
//
//
export default {
  name: "MyTooltip",
  props: {
    content: {
      type: String,
      default: ""
    }
  }
};