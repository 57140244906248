var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: { effect: "dark", placement: "bottom-start" }
    },
    [
      _c("div", {
        attrs: { slot: "content" },
        domProps: { innerHTML: _vm._s(_vm.content) },
        slot: "content"
      }),
      _c("i", { staticClass: "el-icon-question" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }